import { Component, Input } from '@angular/core';
// import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

}