import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { SharedModule } from '../common/shared.module';
import { PosterValidator } from './poster-validator';
import { PostersGridComponent } from './posters-grid.component';
import { CommonModule } from '@angular/common';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { AuthorsGridComponent } from './authors-grid.component';
import { ValidationOptionsDialog } from './validation-options.dialog';

@NgModule({
	imports: [
		CommonModule,
		GridModule,
		ExcelModule,
		SharedModule,
		ButtonModule,
		MatTooltipModule,
		MatButtonToggleModule
	],
	declarations: [PostersGridComponent, AuthorsGridComponent, ValidationOptionsDialog],
	entryComponents: [AuthorsGridComponent, ValidationOptionsDialog],
	providers: [PosterValidator]
})
export class PostersModule {
}
