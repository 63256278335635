import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { of as observableOf, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AuthService } from '../auth/services/auth.service';
import { AppStore } from '../common/app-store';
import { ConfirmDialog } from '../common/dialogs/confirm.dialog';
import { buildPrefix } from '../common/prefix-builder';
import { DataService } from '../common/services/data.service';
import { NewConferenceDialog } from './new-conference.dialog';
import {tap, concat, debounceTime, switchMap, filter} from 'rxjs/operators';
import { EmailService } from '../common/services/email.service';

@Component({
	selector: 'conferences',
	styleUrls: ['./conferences.component.scss'],
	templateUrl: './conferences.component.html'
})
export class ConferencesComponent implements OnInit {
	items: any[];
	archivedItems: any[] = [];
	showArchived: boolean = false;
	isLoading: boolean = false;

	private activeConferences$: Subject<string> = new Subject<string>();
	private archivedConferences$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(
		private data: DataService,
		private dialog: MatDialog,
		private router: Router,
		private auth: AuthService,
		private appStore: AppStore,
		private email: EmailService
	) {
	}

	ngOnInit() {
		this.initModule();
	}

	delete(conference) {
		let data = {
			title: 'Delete website',
			message: `This will delete permanently your website. Are you sure?`,
			buttonConfirm: 'Delete',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data})
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.data.deleteConference(conference);
				this.items = this.items.filter(x => x !== conference);
				console.log('Conference:' + conference.name + ' deleted!');
			}
		});
	}

	editConference(conference: any) {
		this.selectConference(conference);
		this.router.navigate(['./shell/conferences', conference.$key]);
	}

	createConference() {
		let dialogRef = this.dialog.open(NewConferenceDialog, {
			width: '300px'
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.isLoading = true;
				let conference = {
					author: this.auth.id,
					name: result.conferenceName,
					prefix: buildPrefix(result.conferenceName),
					isCreated: false
				};
				this.data.createConference(conference)
					.then((key) => {
						conference['$key'] = key;
						this.selectConference(conference);
						this.router.navigate(['./shell/conferences', key]);
						this.isLoading = false;
						this.email.sendEmail(
							this.subjectOnCreation(conference.name),
							this.bodyOnCreation(conference)
						);
					});
			}
		});
	}

	archiveConference(conference) {
		let data = {
			title: 'Archive event',
			message: `Do you want to archive the event "${conference.name}"?`,
			buttonConfirm: 'Archive',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.data.archiveConference(conference);
				if (this.appStore.selectedConference && conference.$key === this.appStore.selectedConference.$key) {
					this.appStore.clearSelectedConference();
				}
				this.items = this.items.filter(item => item.$key !== conference.$key);
				this.archivedItems.push(conference);
			}
		});
	}

	unarchiveConference(conference) {
		this.data.unarchiveConference(conference);
		this.archivedItems = this.archivedItems.filter(item => item.$key !== conference.$key);
		this.items.push(conference);
	}

	selectConference(conference) {
		this.appStore.selectConference(conference);
	}

	private initModule() {
		let userId = this.auth.isAdmin() ? null : this.auth.id;

		observableOf('').pipe(
			concat(this.activeConferences$),
			debounceTime(300),
			tap(() => this.isLoading = true),
			switchMap(myFilter => this.data.loadActiveConferences(userId, myFilter)),
			tap(() => this.isLoading = false)
		).subscribe(items => this.items = items);

		this.archivedConferences$.pipe(
			filter(() => this.showArchived),
			debounceTime(300),
			tap(() => this.isLoading = true),
			switchMap(myFilter => this.data.loadArchivedConferences(userId, myFilter)),
			tap(() => this.isLoading = false)
		).subscribe(items => this.archivedItems = items);
	}

	search(myFilter) {
		this.activeConferences$.next(myFilter);
		this.archivedConferences$.next(myFilter);
	}

	toggleArchived() {
		this.showArchived = !this.showArchived;
		this.archivedConferences$.next(this.archivedConferences$.value);
		if (!this.showArchived) {
			this.archivedItems = [];
		}
	}

	subjectOnCreation(name): String {
		const subject: String = '[BetterEvents] A new event ' + name + ' has been created';
		return subject;
	}

	bodyOnCreation(event) {
		let body: String;
		const username = this.auth.getName() ? this.auth.getName() + ' - ' : '';
		body = 'A new event was just created';
		body += '\n';
		body += '\nEvent name: ' + (event.name ? event.name : 'not set');
		body += '\nLink: https://betterevents.app/#/shell/conferences/' + (event.$key ? event.$key : 'not set');
		body += '\nUser: ' + username + this.auth.getEmail();
		body += '\n';
		body += '\nCheers';
		body += '\nBetterEvents team';
		return body;
	}
}
