import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
	templateUrl: 'sign-up.component.html',
	styleUrls: ['sign-up.component.scss']
})

export class SignUpComponent {
	signupForm: FormGroup;
	error: string;

	appName = '';
	appSubtitle = '';
	appSlogan = '';

	isLoading: boolean = false;
	privacyCboxValidationMessage: string = '';

	constructor(private auth: AuthService, private router: Router, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.signupForm = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
			privacyCbox: [false, Validators.requiredTrue]
		});

		this.error = '';

		this.appName = environment.app.name;
		this.appSubtitle = environment.app.subtitle;
		this.appSlogan = environment.app.slogan;
	}

	signUp() {
		// if (this.privacyPolicyAgree()) {
			let data = this.signupForm.value;
			this.isLoading = true;
			this.auth.signUp(data.email, data.password).then(
				() => this.postSignUp(),
				error => {
					this.isLoading = false;
					this.error = error.message;
				}
			);
		// }
	}

	signInWithGoogle(): void {
		// if (this.privacyPolicyAgree()) {
			this.auth.signInWithGoogle()
				.then(() => this.postSignUp());
		// }
	}

	private privacyPolicyAgree(): boolean {
		if (!this.signupForm.get('privacyCbox').value) {
			this.privacyCboxValidationMessage = 'You must agree with our Privacy Policy to proceed.';
			return false;
		} else {
			return true;
		}
	}

	private postSignUp(): void {
		this.isLoading = false;
		this.router.navigate(['/shell']);
	}
}
