import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
// import { Poster } from '../../posters/poster';
import { uuid } from '../uuid';
import { SqsService } from './aws-sqs.service';
import { map } from 'rxjs/operators';


@Injectable()
export class DataService {
	constructor(
		private afDB: AngularFireDatabase,
		private http: Http,
		private sqs: SqsService
	) {
	}

	probeDomain(url: string): Observable<any> {
		return this.http.get(url);
	}

	deleteConference(conference) {
		console.log('Event: ' + conference.name + ' will be deleted!');
		this.deleteConferenceFromFirebase(conference.$key)
			.then(() => {
				console.log('Event: ' + conference.name + ' removed from Firebase!');
				if (conference.locked) {
					this.sendSQSMessage(conference, 'delete');
				}
			},
			err => {
				console.log('An error occured: ' + err);
			});
	}

	deleteConferenceFromFirebase(conferenceId: string): Promise<any> {
		return this.afDB.object('conferences/' + conferenceId).remove();
	}

	sendSQSMessage(conference, mode) {
		let theme = conference.selectedTheme;
		let separator = ':';
		let prefix = conference.prefix + conference.startDate.year;

		let params = {
			MessageBody: prefix + separator + theme + separator + mode,
			MessageDeduplicationId: mode + '_' + prefix,
			MessageGroupId: prefix,
			MessageAttributes: {
				'Action': {
					DataType: 'String',
					StringValue: mode
				}
			}
		};
		// console.log('Flag for installation repository: ' + sqsFlag);
		this.sqs.sendMsg(params)
			.then((data) => {
				console.log(data);
				// this.openSnackBar('Deletion started');
			})
			.catch((error) => {
				console.log(error);
				// this.openSnackBar('An error occured', true);
			});
	}


	createConference(conference: any): Promise<string> {
		let key = uuid();
		conference.updatedAt = firebase.database.ServerValue.TIMESTAMP;
		return this.afDB.object(`conferences/${key}`)
			.set(conference)
			.then(() => key);
	}

	loadPosters(conferenceId: string): Observable<any[]> {
		let query = ref => ref.orderByChild('conferenceId').equalTo(conferenceId);
		return this.afDB.list('posters', query).snapshotChanges().pipe(
			map(actions => actions.map(action => ({ $key: action.key, ...action.payload.val() }))));
	}

	savePoster(poster: any, key: string, conferenceId: string) {
		poster.conferenceId = conferenceId;

		key = poster.$key || key || uuid();
		let update = this.patchEntity(poster);
		return this.afDB.object(`posters/${key}`).update(update);
	}

	removePoster(poster: any) {
		let key = poster.$key;
		return this.afDB.object(`posters/${key}`).remove();
	}

	removeAllPosters(posters: any): Promise<any> {
		let promises = [];
		posters.forEach(poster => {
			promises.push(this.afDB.object(`posters/${poster.$key}`).remove());
		});
		return Promise.all(promises);
	}

	savePosterAuthor(posterKey: string, authorKey: string, author: any) {
		authorKey = authorKey || uuid();
		author.id = authorKey;
		let update = this.patchEntity(author);
		return this.afDB.object(`posters/${posterKey}/authors/${authorKey}`).set(update);
	}

	removePosterAuthor(posterId: string, authorId: string) {
		return this.afDB.object(`posters/${posterId}/authors/${authorId}`).remove();
	}

	importPosters(posters: any[], conferenceId: string) {
		let list = this.afDB.list(`posters`);
		posters.forEach(poster => {
			poster.conferenceId = conferenceId;
			list.push(poster);
		});
	}

	loadActiveConferences(author, filter): Observable<any[]> {
		let authorQuery = author ? `&author=${author}` : '';
		let url = `${environment.cloudFunctions.baseUrl}/getConferences?filter=${filter}${authorQuery}`;

		return this.http.get(url)
			.map(x => x.json())
			.map(x => x.items);
	}

	loadArchivedConferences(author, filter): Observable<any[]> {
		let authorQuery = author ? `&author=${author}` : '';
		let url = `${environment.cloudFunctions.baseUrl}/getConferences?filter=${filter}&archived=true${authorQuery}`;

		return this.http.get(url)
			.map(x => x.json())
			.map(x => x.items);
	}

	loadConference(id: string): Observable<any> {
		return this.afDB.object(`conferences/${id}`).snapshotChanges()
			.map(x => ({ $key: x.key, ...x.payload.val() }));
	}

	archiveConference(conference: any): Promise<void> {
		let update = {
			$key: conference.$key,
			archived: true
		};
		return this.saveConference(update);
	}

	unarchiveConference(conference: any): Promise<void> {
		let update = {
			$key: conference.$key,
			archived: false
		};
		return this.saveConference(update);
	}

	saveConference(conference: any): Promise<void> {
		conference.updatedAt = firebase.database.ServerValue.TIMESTAMP;
		return this.saveItem('conferences', conference);
	}

	validatePrefix(key: string, prefix: string, year: number): Observable<{ valid: boolean }> {
		return this.http.get(`${environment.cloudFunctions.baseUrl}/validatePrefix?prefix=${prefix}&key=${key}&year=${year}`)
			.map(x => x.json());
	}

	loadItems(type: string): Observable<any[]> {
		return this.afDB.list(type).snapshotChanges()
			.map(actions => actions.map(action => ({ $key: action.key, ...action.payload.val() })));
	}

	loadItemsByParent(collection: string, parentId: string): Observable<any[]> {
		let query = ref => ref.orderByChild('parentId').equalTo(parentId);
		return this.afDB.list(collection, query).valueChanges();
	}

	createItem(itemType: string, item: any): Promise<void> {
		let key = uuid();
		return this.afDB.object(`${itemType}/${key}`).set(item);
	}

	deleteItem(itemType: string, item: any): Promise<void> {
		let key = item.$key;
		return this.afDB.object(`${itemType}/${key}`).remove();
	}

	loadItem(itemType: string, itemId: string): Observable<any> {
		return this.afDB.object(`${itemType}/${itemId}`).valueChanges();
	}

	saveItem(itemType: string, item: any): Promise<void> {
		let key = item.$key;
		let update = this.patchEntity(item);
		return this.afDB.object(`${itemType}/${key}`).update(update);
	}

	private patchEntity(item: any): any {
		let update = JSON.parse(JSON.stringify(item));
		delete update.$key;
		return update;
	}
}
