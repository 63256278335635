import { Component } from '@angular/core';
import { AppStore } from '../common/app-store';

@Component({
	selector: 'home',
	styleUrls: ['./home.component.scss'],
	templateUrl: './home.component.html'
})
export class HomeComponent {
	constructor(public appStore: AppStore) {
	}

	clearSelection() {
		this.appStore.clearSelectedConference();
	}
}
