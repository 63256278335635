import * as Papa from 'papaparse';
import { uuid } from '../common/uuid';

let requiredFields = [
	'Code',
	'Title',
	'Topic',
	'Subtopic',
	'Session',
	'Corresponder First Name',
	'Corresponder Last Name',
	'Corresponder Email',
	'Author First Name',
	'Author Last Name',
	'Author Affiliation',
	'Author Email'
];

const validate = (data) => {
	if (data.errors && data.errors.length) {
		console.log(data.errors);
		return {
			error: data.errors
		};
	}
	let missedFields = requiredFields.filter(field => data.meta.fields.every(x => field !== x));
	if (missedFields.length > 0) {
		console.log('Missed fields: ', missedFields);

		return {
			error: 'Missed fields',
			missedFields: missedFields
		};
	}

	return null;
};

export const parsePostersCsv = (csv: string): any => {
	let result = Papa.parse(csv, {
		header: true
	});

	let error = validate(result);
	if (error) {
		return error;
	}

	let groupedPosters = result.data
		.reduce((acc, current) => {
			let code = current['Code'];
			if (!code) {
				return acc;
			}

			// Poster fields
			let poster = acc[code] || { };
			acc[code] = poster;
			poster.code = poster.code || code;
			poster.title = poster.title || current['Title'];
			poster.topic = poster.topic || current['Topic'];
			poster.subtopic = poster.subtopic || current['Subtopic'];
			poster.session = poster.session || current['Session'];

			// Corresponder fields
			let corresponder = poster.corresponder || {};
			poster.corresponder = corresponder;
			corresponder.firstName = corresponder.firstName || current['Corresponder First Name'];
			corresponder.lastName = corresponder.lastName || current['Corresponder Last Name'];
			corresponder.email = corresponder.email || current['Corresponder Email'];

			// Authors
			let authors = poster.authors || {};
			poster.authors = authors;

			let id = uuid();
			authors[id] = {
				id: id,
				firstName: current['Author First Name'],
				lastName: current['Author Last Name'],
				email: current['Author Email'],
				affiliation: current['Author Affiliation']
			};

			return acc;
		}, {});
	let posters = Object.keys(groupedPosters).map(code => groupedPosters[code]);
	return {
		data: posters
	};
};