import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CKEditorConfig } from '../common/ckeditor.config';
import { ValidationService } from '../common/formly/validation.service';
import { FireStorage } from '../common/services/fire-storage';
import { HTML_TEMPLATE } from './html-template';

export const conferenceFormConfig = (form: FormGroup, storage: FireStorage, isAdmin: boolean): { [tab: string]: FormlyFieldConfig[] } => ({
	details: [
		{
			type: 'html',
			templateOptions: {
				content: `
					<h4>General information about your event</h4>
					<p>To get started with your event, please provide some basic information.
					</p>
				`
			}
		}, {
			type: 'input',
			key: 'name',
			templateOptions: {
				label: 'Event name',
				description: 'Provide the official name of your event.',
			},
			validators: {
				validation: Validators.compose([Validators.required, Validators.maxLength(120)])
			},
			validation: {
				messages: {
					required: ValidationService.getValidatorErrorMessage('required') + ' Please provide the official name of your event.',
					maxlength: 'Max length of \"Event name\" is 120 characters.'
				}
			}
		}, {
			type: 'multi-select',
			key: 'selectedTheme',
			defaultValue: 'event',
			hide : true,
			templateOptions: {
				multiple: false,
				label: 'Select a theme',
				labelProp: 'title',
				valueProp: 'value',
				collection: 'themes',
				warnMessage: ValidationService.getValidatorErrorMessage('required') + ' Please select the theme to be installed.'
			},
			expressionProperties: {
				'templateOptions.warn': '!model.selectedTheme'
			}
		}, {
			fieldGroupClassName: 'row',
			fieldGroup: [
				{
					className: 'col-6',
					type: 'as-datepicker',
					key: 'startDate',
					templateOptions: {
						label: 'Start Date',
						warnMessage: ValidationService.getValidatorErrorMessage('required') + ' Please provide the Start Date of your event.'
					},
					expressionProperties: {
						'templateOptions.warn': '!model.startDate'
					}
				},
				{
					className: 'col-6',
					type: 'as-datepicker',
					key: 'endDate',
					templateOptions: {
						label: 'End Date',
						warnMessage: ValidationService.getValidatorErrorMessage('required') + ' Please provide the End Date of your event.',
						minDateField: 'startDate'
					},
					expressionProperties: {
						'templateOptions.warn': '!model.endDate'
					},
					validators: {
						validation: ValidationService.greaterOrEqualDate(form, 'startDate')
					},
					validation: {
						messages: {
							not_matching: 'The End Date should be greater than the Start Date'
						}
					}
				}
			]
		}, {
			type: 'prefix',
			key: 'prefix',
			templateOptions: {
				label: 'Prefix',
				warnMessage: ValidationService.getValidatorErrorMessage('required') + ' ' + ValidationService.getValidatorErrorMessage('prefixError'),
				baseField: 'name',
				description: 'The recommended prefix above was generated using the initial letters of your event name. You may edit the prefix above. Please keep in mind you should keep it short and use only latin lower-case letters.'
			},
			expressionProperties: {
				'templateOptions.warn': '!model.prefix || model.prefix.length < 2'
			}
		}, /* {
			type: 'input',
			key: 'webSite',
			templateOptions: {
				label: 'Official website of your event',
				placeholder: 'e.g.: http://www.your-event.com'
			},
			// validators: {
			// 	validation: ValidationService.urlValidator
			// },
			// validation: {
			// 	messages: {
			// 		invalidUrl: ValidationService.getValidatorErrorMessage('invalidUrl')
			// 	}
			// }
		}, {
			type: 'input',
			key: 'location',
			templateOptions: {
				label: 'Location of your Event',
				description: 'This is referenced in the online ePoster archive that is prepared for your event.',
				placeholder: 'e.g.: Hilton, Athens, Greece'
			}
		}, {
			type: 'input',
			key: 'nameOfManager',
			templateOptions: {
				label: 'Meeting Manager\'s Full Name',
				description: 'This is not shown publicly nor shared with any other party, and is only used for correspondence between our team and you.'
			}
		},*/ {
			type: 'input',
			key: 'email',
			templateOptions: {
				label: 'Meeting Manager\'s Email Address',
				description: 'This is not shown publicly nor shared with any other party, and is only used for correspondence between our team and you.',
				// warnMessage: ValidationService.getValidatorErrorMessage('invalidEmailAddress')
				warnMessage: 'We will not be able to reach out to you if your email address field is blank or invalid.'
			},
			// validators: {
			// 	validation: ValidationService.emailValidator
			// },
			// validation: {
			// 	messages: {
			// 		invalidEmailAddress: ValidationService.getValidatorErrorMessage('invalidEmailAddress')
			// 	}
			// },
			expressionProperties: {
				'templateOptions.warn': (model) => {
					let control = {value: model.email};
					return  !model.email || ValidationService.emailValidator(control);
				}
			}
		},/* {
			type: 'input',
			key: 'phonenumber',
			templateOptions: {
				label: 'Phone Number',
				placeholder: 'e.g.: (555) 555-1234',
				description: 'This is not shown publicly nor shared with any other party, and is only used for correspondence between our team and you.'
			}
		} */
	],
/*	dates: [
		{
			type: 'html',
			templateOptions: {
				content: `
				<h3>Overview</h3>
				<p>The user experience of the ePoster submitting author is shown in the short videos that follow:
				<ol>
					<li><a href="https://www.youtube.com/watch?v=7sCyZ0rkuAY" target="_blank">The author's user-experience with <em>e</em>Posters<strong>Live</strong><sup>®</sup> | Pt.1 - Before the ePoster Submission</a></li>
					<li><a href="https://www.youtube.com/watch?v=7nXS2S1lpX8" target="_blank">The author's user-experience with <em>e</em>Posters<strong>Live</strong><sup>®</sup> | Pt.2 - Submitting an ePoster</a></li>
				</ol></p>
				<br>
				<p>ePoster Submissions start by</p>
				<ul>
				<li>setting the date when the system dedicated to your event will go live and the date it will close, </li>
				<li>and sending out an email with the creation and submission instructions to corresponding authors.</li>
				</ul>
				<h4>2.1. Dates for opening and closing ePoster Submissions</h4>
			`
			}
		}, {
			type: 'date-picker',
			key: 'submissionStartDate',
			templateOptions: {
				label: 'Start Date',
				description: `When would you like to get started with ePoster submissions? It is technically feasible
				to start the submissions within 48 hours after you have uploaded your list of abstracts accepted as ePosters.`
			}
		}, {
			type: 'date-picker',
			key: 'submissionEndDate',
			templateOptions: {
				label: 'Deadline',
				description: `Tip: 9/10 organizers set a deadline around a week before the start date of your event.
				This enables them to grant a couple of extensions to the initial deadline if the need occurs.`,
				warnMessage: 'Submission deadline should be 10 days before the start date',
				minDateField: 'submissionStartDate'
			},
			expressionProperties: {
				'templateOptions.warn': 'formState.rules.submissionDeadline10DaysBeforeStartDate(model)'
			},
			validators: {
				validation: ValidationService.greaterOrEqualDate(form, 'submissionStartDate')
			},
			validation: {
				messages: {
					not_matching: 'The Submission End Date should be greater than the Submission Start Date'
				}
			}
		}, {
			type: 'html',
			templateOptions: {
				content: `
				<h4>2.2. Instructions to ePoster Authors</h4>
				<p>Instructions to ePoster Authors are provided in three ways:</p>
				<ol>
				<li>through the homepage of the ePosterSubmission system dedicated to your event </li>
				<li>in a personalized email with instructions that is sent out through <em>e</em>Posters<strong>Live</strong><sup>®</sup></li>
				<li>through the <em>e</em>Posters<strong>Live</strong><sup>®</sup> <strong>Helpdesk for ePoster Authors</strong>, which will be set up for the ePoster program of your event</li>
				</ol>
				<p>Each of the above can feature a banner of your event. You can upload banners below.</p>
			`
			}
		}, {
			type: 'file-upload',
			key: 'banner1',
			templateOptions: {
				label: 'Banner for the email with instructions to ePoster authors',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				buttonLabel: 'Upload',
				description: 'Recommended: 600 px wide x Any px high'
			}
		}, {
			type: 'file-upload',
			key: 'banner2',
			templateOptions: {
				label: 'Banner for the ePosterSubmission system your ePoster authors will use to submit their ePoster',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				buttonLabel: 'Upload',
				description: 'Recommended: 728px wide x 90px high and should not exceed 144px in height',
				validators: {
					imageSize: (img) => img.height > 144 ? 'Image height should not exceed 144px' : null
				}
			},
		}, {
			type: 'rich-text',
			key: 'legalTemplate',
			templateOptions: {
				label: 'Legal text for ePoster Authors',
				config: CKEditorConfig.extendedToolbar,
				defaultValue: 'I agree to have my <b>ePoster</b> published on the website dedicated to the ePosters of the event.',
				description: 'Legal disclaimer shown along with a checkbox in the ePosterSubmission system. You can change the default wording provided so that it conforms to the legal framework of your event or organization.'
			}
		}, {
			type: 'html',
			templateOptions: {
				content: `
				<h4>2.3. ePoster Format(s)</h4>
				<p><em>e</em>Posters<strong>Live</strong><sup>®</sup> enables you to include into your ePoster program,</p>
			`
			}
		}, {
			type: 'multi-checkbox',
			key: 'eposterType',
			templateOptions: {
				label: 'Choose any of the options that may meet your needs of your ePoster program',
				options: [{
					key: 'ePosterMultiSlide',
					value: 'Multi-slide ePosters',
					// description: ' (<a href="#">see video demo</a>)'
				}, {
					key: 'ePosterWithVideo',
					value: 'ePosters with associated video',
					description: '(up to 5 video)'
				}, {
					key: 'ePosterWithAudio',
					value: 'ePosters with associated audio',
					description: '(up to 5 audio)'
				}, {
					key: 'ePosterInteractive',
					value: 'Interactive ePosters (beta)',
					// description: ' (<a href="#">see video demo</a>)'
				}]
			}
		}
	], */
/*	acceptedAbstracts: [
		{
			type: 'html',
			templateOptions: {
				content: `
				<h4>Your abstracts accepted as ePoster presentations</h4>
				<p>The most basic information needed to get started is the list of abstracts accepted as ePosters in your event.
				The list should be uploaded as a CSV or spreadsheet (XLS, XLSX).</p>
				<p>We strongly recommend that you structure your list according to <a href="https://drive.google.com/open?id=0B4cTEiDKrKv_QVNlWk53b0NRVjg" target="_blank">this example</a>. This structure includes all the information that can be accommodated and speeds up the ePosters<strong>Live</strong> backend system preparation process.</p>
				</p>
			`
			}
		}, {
			type: 'file-upload',
			key: 'abstractCSV',
			templateOptions: {
				label: 'Upload your list of abstracts accepted as ePosters',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				buttonLabel: 'Upload',
				description: 'Continuing with the upload, you confirm that you have the consent to share the personal information included in the uploaded file.'
			}
		}
	], */
/*	monetization: [
		{
			type: 'html',
			templateOptions: {
				content: `
					<h4>Cover the costs of your ePoster program</h4>
					<p>With <em>e</em>Posters<strong>Live</strong><sup>®</sup> you have the option to cover the costs of your ePoster program in part or in full with the built-in revenue generating options:</p>
					<ol>
						<li>Setting a fee per ePoster Submission</li>
						<li>Including sponsors into the ePoster System.</li>
					</ol>

					<h5>Setting a fee per ePoster Submission</h5>
					<p>With traditional poster boards, authors pay for the poster printout. With <em>e</em>Posters<strong>Live</strong><sup>®</sup> you have the option to capture part of those funds by setting an submission fee for the author (usually 60-70% of the cost of the printout), to cover the cost of ePosters in part or in full.</p>
					<p>As an example, by charging submitting authors just $60.00 per submission, which is almost 50% of the cost of the poster printout, you could generate:</p>

					<p><strong>Example: ($60 / submission) x (100 submissions) ~ $6,000</strong><sup>[1]</sup></p>

					<p>The price is set by the organizers. The process of paying a fee to submit a poster is completely automated, includes invoicing to the authors and has been used extensively for tens of thousands of ePoster submissions by different event organizers. Any guidance the authors may need for paying is included with our Live and email-based support.</p>

					<small><sup>[1]</sup> A commission of 4.9% + 1 USD per transaction (ePoster submission) is charged by the <a href="http://www.digitalriver.com" target="_blank">eCommerce company</a> that undertakes the secure online transactions with automated invoicing to poster submitting authors as soon as their payment comes through.</small>

					<br><br>
				`
			}
		}, {
			type: 'check',
			key: 'activateSubmissionFee',
			templateOptions: {
				label: 'Activate eCommerce Module'
			}
		}, {
			type: 'input',
			key: 'submissionFee',
			templateOptions: {
				type: 'number',
				label: 'Set submission fee',
				placeholder: '65',
				description: 'Consider using a submission fee around 60-70% of what the poster authors would either way pay for poster printing.'
			},
			hideExpression: '!model.activateSubmissionFee'
		}, {
			key: 'submissionFeeCurrency',
			type: 'select',
			templateOptions: {
				options: [{
					label: 'USD',
					value: 'USD'
				}, {
					label: 'EUR',
					value: 'EUR'
				}, {
					label: 'GBP',
					value: 'GBP'
				}],
				label: 'Submission fee currency',
				// placeholder: 'Select currency'
			},
			hideExpression: '!model.activateSubmissionFee'
		}, {
			key: 'submissionFeeGrossNet',
			type: 'select',
			templateOptions: {
				options: [{
					label: 'Gross, incl. Tax Charges where applicable',
					value: 'gross'
				}, {
					label: 'Net, not including Tax Charges where applicable',
					value: 'net'
				}],
				label: 'Is the fee to be charged net or gross?',
				description: 'e.g.: Net: $65 + Taxes, in case taxes are applicable. Gross: $65 including Taxes, where taxes are applicable.'
			},
			hideExpression: '!model.activateSubmissionFee'
		}
	], */
	website: [
		/*{
			type: 'html',
			templateOptions: {
				content: `


					<h5>Building sponsors into your ePoster System</h5>
					<p>In this step you can upload images from your sponsors to be displayed in predefined spots in your ePoster system<br>
					Read the detailed Ads Guide <a href="https://s3-eu-west-1.amazonaws.com/my.eposterslive.com/ad-tech-specs.pdf" target="_blank">here</a>.
					</p>
				`
			}
		}, {
			type: 'file-upload',
			key: 'ad-slot-1',
			templateOptions: {
				label: 'Ad slot 1: Sidebar banners (up to 4 images)',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				description: 'Dimensions: 300 x 250 px',
				validators: {
					imageSize: (img) => img.width !== 300 || img.height !== 250 ? 'Image dimensions must be 300x250 px' : null
				},
				maxFiles: 4
			}
		},
		{
			type: 'file-upload',
			key: 'ad-slot-2',
			templateOptions: {
				label: 'Ad slot 2: Header and footer banners (up to 2 images)',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				description: 'Dimensions: 468 x 60 px wide by any height',
				maxFiles: 2
			}
		}, {
			type: 'file-upload',
			key: 'ad-slot-3',
			templateOptions: {
				label: 'Ad slot 3: Ribbon banners (up to 4 images)',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				description: 'Dimensions: 80px height and up to 1920 width',
				validators: {
					imageSize: (img) => img.width > 1920 || img.height !== 80  ? 'Image must be 80px height and up to 1920px width' : null
				},
				maxFiles: 4
			}
		}, {
			type: 'file-upload',
			key: 'ad-slot-4',
			templateOptions: {
				label: 'Ad slot 4: Full screen ads (up to 6 images)',
				change: (file: File) => storage.upload(file),
				remove: (url: string) => storage.remove(url),
				disabled: true,
				description: 'Dimensions: 1920 x 1080 px',
				validators: {
					imageSize: (img) => img.width !== 1920 && img.height !== 1080 ? 'Image must be: 1920 x 1080 px' : null
				},
				maxFiles: 6
			}
		}*/
	],
	content: [
		{
			type: 'html',
			templateOptions: {
				content: `
				<h4>Prepare your content</h4>
				<h5>Instructions</h5>
				<p>
					Check our
					 <a href="https://docs.google.com/document/d/1VCcaLEdbjhfRESUDlInFo89IQAqm1BuX1iUBbm2PRO8/edit?usp=sharing" target="_blank">guidelines</a>
					 on how to prepare your own content for import. Download the sample
				   <a href="https://docs.google.com/spreadsheets/d/1QUIQEzWiHCfZe35s8yIhAE-j538l4FS-a-dV4KiulHg/edit?usp=sharing" target="_blank">spreadsheet</a>
					 and fill in your Speakers, Sponsors, Affiliations and Sessions.
				</p>
				<h5>Upload</h5>
				<p>
					Upload the spreadsheet with your content and mark it as ready for import.
					 Our team will take care of the rest and populate your website. You will be notified when it is ready.

				</p>
				`
			}
		},
		{
			type: 'file-upload',
			key: 'eventCSV',
			templateOptions: {
				// label: '',
				storage: 'FIRE',
				disabled: true,
				buttonLabel: 'Upload',
				description: 'Upload your content file.'
			}
		}
		// {
			// type: 'html',
			// templateOptions: {
			// 	content: `
			// 		<a href="https://www.youtube.com/watch?v=7sCyZ0rkuAY" target="_blank">ePoster submission system</a>
			// 	`
			// }
		// },
		// {
			// type: 'html',
			// templateOptions: {
			// 	content: `
			// 		<a href="https://www.youtube.com/watch?v=7sCyZ0rkuAY" target="_blank">HelpDesk</a>
			// 	`
			// }
		// }
	],
	addons: [{}]
});
