import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(private auth: AuthService) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {

		if (!next.data || !next.data.allowFor || next.data.allowFor.length === 0) {
			return true;
		}

		return _.intersection(this.auth.roles, next.data.allowFor).length > 0;
	}

}