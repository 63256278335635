import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectionListChange } from '@angular/material';
import { ValidationOption } from './validation-option';

@Component({
	selector: 'validation-dialog',
	templateUrl: './validation-options.dialog.html',
	styleUrls: ['./validation-options.dialog.scss']
})
export class ValidationOptionsDialog {
	validationOptions;
	options: ValidationOption[];

	isSelected(option: ValidationOption) {
		return this.options.some(x => x === option);
	}

	constructor(private dialogRef: MatDialogRef<ValidationOptionsDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
		this.options = data.options;
		this.validationOptions = ValidationOption;
		console.log(this.validationOptions);
	}

	ok() {
		this.dialogRef.close(this.options);
	}

	cancel() {
		this.dialogRef.close();
	}

	selectionChange(event: MatSelectionListChange) {
		if (event.option.selected) {
			this.options.push(event.option.value);
		} else {
			this.options = this.options.filter(x => x !== event.option.value);
		}
	}
}