import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValidationSummary } from './form-validation-summary/form-validation-summary';
import { PrefixComponent } from './prefix/prefix.component';
// import { MatInputModule } from '@angular/material/input';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		// MatInputModule
	],
	exports: [PrefixComponent, FormValidationSummary],
	declarations: [PrefixComponent, FormValidationSummary]
})
export class ComponentsModule {
}
