import { FormlyFieldConfig } from '@ngx-formly/core';

export class ConferenceStore {
	index: number = 0;
	fields: { [name: string]: FormlyFieldConfig[] };
	isCreated: boolean = false;

	get numberOfTabs() {
		return Object.keys(this.fields).length;
	}

	get canGoNext() {
		return (this.index < this.numberOfTabs - 1 && this.isCreated) || this.index === 0;
	}

	get canGoBack() {
		return this.index > 0;
	}

	constructor(fields: { [name: string]: FormlyFieldConfig[] }) {
		this.fields = fields;
	}

	next() {
		if (this.canGoNext) {
			this.index += 1;
		}
	}

	prev() {
		if (this.canGoBack) {
			this.index -= 1;
		}
	}
}