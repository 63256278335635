import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { ServerResponse } from './server-response';

export class Conference {
	endDate: any;
	submissionEndDate: any;
	prefix: string;
	startDate: { year: string; };
	// xreiazomaste ayti tin klasi? pws ginetai na dimiourgoume mia klasi k parakatw na leme oti ena object einai autou tou tupou "createConference(conference: Conference)"
}

@Injectable()
export class RestService {
	constructor(private http: Http) {
	}

	private extractFileUrls(conference) {
		let obj = {
			banner1: '',
			banner2: ''
			// ad-slot-1:
		};
		if (conference.banner1 && conference.banner1.length > 0) {
			obj.banner1 = conference.banner1[0].url;
		}
		if (conference.banner2 && conference.banner2.length > 0) {
			obj.banner1 = conference.banner2[0].url;
		}

		return obj;
	}

	createConference(conference: Conference): Observable<ServerResponse> {
		let fileUrls = this.extractFileUrls(conference);
		let body = Object.assign({}, conference, fileUrls, {
			Prefix: conference.prefix + conference.startDate.year,
			startDate: this.dateSQLformat(conference.startDate),
			endDate: this.dateSQLformat(conference.endDate),
			submissionEndDate: this.dateSQLformat(conference.submissionEndDate)
		});
		return this.cloudPost('createConference', body);
	}

	sendEmails(sendEmailRequest: any): Observable<ServerResponse> {
		return this.cloudPost('sendEmail', sendEmailRequest);
	}


	getAdminEmails() {
		return this.cloudGet('adminEmails');
	}

	activateConference(conference: Conference) {
		let body = Object.assign({}, conference, {
			Prefix: conference.prefix + conference.startDate.year,
			Action: 'OPEN'
		});
		return this.cloudPost('activateConference', body);
	}

	createConferenceDirect(conference: Conference): Observable<ServerResponse> {
		let fileUrls = this.extractFileUrls(conference);
		let body = Object.assign({}, conference, fileUrls, {
			Prefix: conference.prefix + conference.startDate.year,
			startDate: this.dateSQLformat(conference.startDate),
			endDate: this.dateSQLformat(conference.endDate),
			submissionEndDate: this.dateSQLformat(conference.submissionEndDate)
		});
		return this.directPost('conferencedbapi', body);
	}

	activateConferenceDirect(conference: Conference) {
		let body = Object.assign({}, conference, {
			Prefix: conference.prefix + conference.startDate.year,
			Action: 'OPEN'
		});
		return this.directPost('conferencednsapi', body);
	}

	deactivateConferenceDirect(conference: Conference) {
		let body = Object.assign({}, conference, {
			Prefix: conference.prefix + conference.startDate.year,
			Action: 'CLOSE'
		});
		return this.directPost('conferencednsapi', body);
	}

	private dateSQLformat(d: any) {
		if (d) {
			return d.year + '-' + d.month + '-' + d.day;
		}
	}

	private directPost(url: string, data: any): Observable<any> {
		let body: string;
		if (typeof data === 'string') {
			body = data;
		} else {
			body = JSON.stringify(data);
		}

		const headers = new Headers();
		headers.append('x-api-key', environment.backend.key);
		headers.append('Content-Type', 'application/json');

		const options: RequestOptionsArgs = {
			headers: headers
		};

		return this.http.post(environment.backend.baseUrl + url, body, options)
			.map(res => res.json())
			.map(res => JSON.parse(res));
	}

	private cloudGet(url: string): Observable<any> {
		const headers = new Headers();
		const options: RequestOptionsArgs = {
			headers: headers
		};

		return this.http.get(environment.cloudFunctions.baseUrl + url, options)
			.map(res => res.json());
	}

	private cloudPost(url: string, data: any): Observable<any> {
		let body: string;
		if (typeof data === 'string') {
			body = data;
		} else {
			body = JSON.stringify(data);
		}

		const headers = new Headers();
		const options: RequestOptionsArgs = {
			headers: headers
		};

		return this.http.post(environment.cloudFunctions.baseUrl + url, body, options)
			.map(res => res.json());
	}
}

function newFunction() {
	return this;
}
