import { AfterViewInit, Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../auth/services/auth.service';
import { diffDays } from '../common/date-helpers';
import { ConfirmDialog } from '../common/dialogs/confirm.dialog';
import { DataService } from '../common/services/data.service';
import { FireStorage } from '../common/services/fire-storage';
import { RestService } from '../common/services/rest.service';
import { ConferenceStore } from './conference.store';
import { conferenceFormConfig } from './form.config';
import { SqsService } from '../common/services/aws-sqs.service';
import { environment } from '../../environments/environment';
import { FormlyFormEnricher } from '../dynamic-form/formly-form-enricher';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmailService } from '../common/services/email.service';

@Component({
	selector: 'conference',
	templateUrl: './conference.component.html',
	styleUrls: ['./conference.component.scss']
})
export class ConferenceComponent implements AfterViewInit {
	conference: any;
	store: ConferenceStore;
	form: FormGroup;
	conferenceId: string;
	invalidCode = false;
	isAdmin = false;
	isLocked: boolean = false;
	submitMsg: boolean = false;
	isCreated: boolean = false;
	isContentFinalized: boolean = false;
	/*
	/  https://stackoverflow.com/questions/44973064/angular-2-async-pipe-on-a-function
	/  https://stackoverflow.com/questions/46787719/use-async-pipe-on-method-returning-observable
	/  https://github.com/angular/angular/issues/7328
	*/
	siteAvailable$: Observable<any>;

	options: any;

	private debounceTime = 300;
	private prefixSubscription: Subscription;

	domainUrl = environment.domainUrl;
	directDomainUrl = environment.directDomainUrl;
	countdown: any;
	deleted;
	timeoutId: any;
	duplicatePrefix: boolean = false;
	buildProgress$: Observable<any>;
	hideWarning: boolean = true;
	hideError: boolean = true;
	userEmail;

	constructor(
		private fb: FormBuilder,
		private storage: FireStorage,
		private data: DataService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		private rest: RestService,
		auth: AuthService,
		private sqs: SqsService,
		private dialog: MatDialog,
		private enricher: FormlyFormEnricher,
		private email: EmailService
	) {
		this.conferenceId = route.snapshot.params['id'];
		this.isAdmin = auth.isAdmin();
		this.initFormFields();
		this.userEmail = auth.getEmail();
	}

	ngOnInit() {
		this.options = {
			formState: {
				rules: {
					submissionDeadline10DaysBeforeStartDate: (model) => {
						if (!model.startDate || !model.submissionEndDate) {
							return false;
						}
						return diffDays(model.startDate, model.submissionEndDate) < 10;
					}
				},
				disabled: false
			}
		};

		Object.keys(this.store.fields).forEach(tab => {
			this.store.fields[tab]
			.filter(
				// field => field.key
				function findKey(field) {
					return Object.keys(field).forEach(property => {
						if (property === 'key') {
							field.expressionProperties = field.expressionProperties || {};
							field.expressionProperties['templateOptions.disabled'] = 'formState.disabled';
							return field;
						} else if (field[property] && typeof field[property] === "object") {
							return findKey(field[property])
						}
					})
				}
			)
			// .forEach(field => {
				// field.expressionProperties = field.expressionProperties || {};
				// field.expressionProperties['templateOptions.disabled'] = 'formState.disabled';
			// });
		});

		// https://alligator.io/angular/reactive-forms-valuechanges/
		this.onChanges();
	}

	ngAfterViewInit(): void {
		this.loadConference();
	}

	ngOnDestroy() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	onChanges(): void {
		this.form.valueChanges.subscribe(val => {
			console.log('Form value changed:', val);
			console.log(this.form);
			// TODO: Implement AutoSave
			// When, and only, the form is already locked we need to autosave under very
			// specific circumstances:
			// 1. Upload: The form is locked, and becomes dirty. The dirty control is the upload.
			if (this.isLocked && this.form.dirty && this.form.controls.eventCSV.dirty) {
				this.data.saveConference(this.conference).then(() => {
					console.log('upload updated');
					this.form.markAsPristine();
				});
			}
			// 2. Ready for Review toggle: The form is locked, and becomes dirty. The ditry control is the toggle.
		});
	}

	private loadConference() {
		this.data.loadConference(this.conferenceId)
			.take(1)
			.toPromise()
			.then(conference => {
				this.conference = conference;
				this.toggleFormLock(this.conference.locked);
				this.createPrefixValidationSubscription();
				this.isCreated = this.conference.isCreated;
				if (this.isLocked) {
					this.startProbing();
				}
				this.store.isCreated = this.isCreated;
			});
	}

	private createPrefixValidationSubscription() {
		let prefix$ = this.form.valueChanges.pipe(
			map(x => x.prefix),
			debounceTime(this.debounceTime),
			distinctUntilChanged());

		let year$ = this.form.valueChanges.pipe(
			map(x => x.startDate),
			map(startDate => startDate && startDate.year),
			distinctUntilChanged());

		this.prefixSubscription = prefix$.combineLatest(year$)
			.subscribe(x => {
				let prefix = <any>x[0];
				let year = <any>x[1];

				if (!prefix || prefix.length < 2 || !year) {
					this.invalidCode = true;
					this.duplicatePrefix = false;
					return;
				}

				this.data.validatePrefix(this.conferenceId, prefix, year)
					.subscribe(result => {
						this.invalidCode = !result.valid;
						this.duplicatePrefix = !result.valid;
					});
			});
	}

	@HostListener('window:beforeunload', ['$event'])
	canDeactivate(): Observable<boolean> | boolean {
		return !this.form.dirty;
	}

	createConferenceDirect() {
		this.rest.createConferenceDirect(this.conference)
			.subscribe(response => this.processBackendResult(response, 'The conference has been created'));
	}

	activateConferenceDirect() {
		this.rest.activateConferenceDirect(this.conference)
			.subscribe(response => this.processBackendResult(response, 'The conference has been activated'));
	}

	deactivateConferenceDirect() {
		let data = {
			title: 'Deactivate conference',
			message: `By choosing "Deactivate", the ePosterSubmission system will be no longer accesible.`,
			buttonConfirm: 'Deactivate',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.rest.deactivateConferenceDirect(this.conference)
					.subscribe(response => this.processBackendResult(response, 'The conference has been deactivated'));
			}
		});
	}

	createConferenceCloud() {
		this.rest.createConference(this.conference)
			.subscribe(response => this.processBackendResult(response, 'The conference has been created'));
	}

	activateConferenceCloud() {
		this.rest.activateConference(this.conference)
			.subscribe(response => this.processBackendResult(response, 'The conference has been activated'));
	}

	save() {
		if (this.isLocked) {
			this.snackBar.open(`The event is locked. You can't do any changes to it`, 'Ok', {
				duration: 3000
			});
			return;
		}
		let conference = Object.assign(this.conference, this.form.value);
		this.data.saveConference(conference).then(() => {
			this.form.markAsPristine();

			this.snackBar.open('The event has been saved', 'Ok', {
				duration: 3000
			});
		});
	}

	cancel() {
		this.data.loadConference(this.conferenceId)
			.take(1)
			.toPromise()
			.then(conference => {
				this.conference = conference;
				this.form.reset(conference);

				this.snackBar.open('Changes to the event have been canceled', 'Ok', {
					duration: 3000
				});
			});
	}

	lock(formValidationSummary) {
		let formErrors = formValidationSummary.formErrors;
		for (let f of formErrors) {
			if (f.errors.length || f.warnings.length || this.invalidCode) {
				this.submitMsg = true;
				setTimeout(() => this.submitMsg = false, 3000);
				return false;
			}
		}
		let dialog = {
			title: 'Create website?',
			message: 'Your site will be created now. Are you sure?',
			buttonConfirm: 'Create'
		};
		this.toggleLock(true, dialog);
	}

	unlock() {
		let dialog = {
			title: 'Unlock Event',
			message: 'You are going to unlock this event. Are you sure?'
		};
		this.toggleLock(false, dialog);
	}

	sendSQSmessage () {
		this.data.sendSQSMessage(this.conference, 'install');
	}


	probing(delay): Observable<any> {
		const url = this.directDomainUrl  + '/' + this.conference.prefix + this.conference.startDate.year + '/success.txt';
		return new Observable<any>( (observer) => {
			this.timeoutId = setTimeout( () => {
			observer.next(this.data.probeDomain(url));
			}, delay);
		});
	}


	startProbing(delay: number = 0) {
		this.probing(delay).subscribe( x => {
			x.subscribe( res => {
				if (res.ok) {
					this.hideWarning = false;
					this.siteAvailable$ = res;
					this.calculateCountdown(res._body);
					if (!this.conference.isCreated) {
						this.changeIsCreated();
					}
				}
			},
			err => {
				this.hideError = false;
				this.startProbing(30000);
				const progressUrl = this.directDomainUrl  + '/' + this.conference.prefix + this.conference.startDate.year + '/progress.txt?r=' + Date.now();
				/* subscribe vs | async
				*    https://blog.angularindepth.com/angular-question-rxjs-subscribe-vs-async-pipe-in-component-templates-c956c8c0c794
				*/
				this.buildProgress$ = this.data.probeDomain(progressUrl);
				// this.data.probeDomain(progressUrl).subscribe(r => {
				// 	console.log(r._body);
				// });
				console.log(err);
			});
		});
	}

	changeIsCreated() {
		let conference = {
			isCreated: true
		};
		this.saveConferenceProperty(conference);
	}

	saveConferenceProperty(conference) {
		let property = Object.keys(conference)[0];
		conference = Object.assign(this.conference, conference);
		this.data.saveConference(conference).then( () => {
			this[property] = this.conference[property];
			console.log('success');
		}, err => {
			console.log(err);
		});
	}

	finalizeContent(event) {
		let conference = {
			isContentFinalized: event.checked
		};
		if (event.checked) {
			this.email.sendEmail(
				this.subjectOnImport(this.conference.name),
				this.bodyOnImport(this.conference)
			);
			this.snackBar.open(`Our team has been notified and your content processing will begin soon.`, ``, {
				duration: 3000
			});
		} else {
			this.snackBar.open(`You have stopped the import process.`, ``, {
				duration: 3000
			});
		}
		this.saveConferenceProperty(conference);
	}

	calculateCountdown(date): any {
		this.countdown = new Date(date);
		this.countdown.setDate(this.countdown.getDate() + 3);
		this.deleted = new Date() >= this.countdown ? true : false;
	}

	delete(conference) {
		let data = {
			title: 'Delete site',
			message: `This will delete permanently your site. Are you sure?`,
			buttonConfirm: 'Delete',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data})
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.data.deleteConference(conference);
				// console.log('Conference:' + conference.name + ' deleted!');
			}
		});
	}

	private toggleLock(lock: boolean, dialogMsg) {

		this.dialog.open(ConfirmDialog, { data: dialogMsg })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {

				this.prefixSubscription.unsubscribe();

				let conference = Object.assign(this.conference, this.form.value);
				conference.locked = lock;

				this.data.saveConference(conference).then(() => {
					this.sendSQSmessage();
					this.conference = null;
					this.toggleFormLock(lock);
					this.loadConference();
					if (lock) {
						this.sendEmail();
						// this.startProbing();
					}
					this.snackBar.open(`${ lock ? 'Your site will be live in a moment' : 'The event has been unlocked' }`, 'Ok', {
						duration: 3000
					});
				});

			}
		});
	}

	private initFormFields() {
		this.form = this.fb.group({});
		let fields = conferenceFormConfig(this.form, this.storage, this.isAdmin);
		this.enricher.enrichFields(fields.details);
		this.enricher.enrichFields(fields.content);
		this.store = new ConferenceStore(fields);
	}

	private toggleFormLock(lock: boolean) {
		this.isLocked = lock;
		this.options.formState.disabled = lock;
	}

	// receives a response from an observable and opens snack-bar with a success/error message
	private processBackendResult(response, successMessage: string) {
		if (response.Result === 'Error') {
			console.log(response.Error);
			if (response.Error.Code === 'ConferenceDBExists') {
				this.snackBar.open('Conference with such a prefix already exists', 'Ok', {
					duration: 3000,
					panelClass: ['error-snack']
				});
			} else {
				this.snackBar.open(response.Error.Description || 'An error occurred', 'Ok', {
					duration: 3000,
					panelClass: ['error-snack']
				});
			}
		} else {
			this.snackBar.open(successMessage, 'Ok', {
				duration: 3000
			});
		}
	}

	private openSnackBar(message: string, error = false) {
		this.snackBar.open(message, 'Ok', {
			duration: 3000,
			panelClass: [error ? 'error-snack' : 'success-snack']
		});
	}

	sendEmail() {
		// this.email.sendEmail(
		// 	this.subjectOnBuild(this.form.value.name),
		// 	this.prepareEmailBody(),
		// 	this.form.value.email
		// ).subscribe(() => {
		// 	this.snackBar.open(`${ this.isAdmin ? 'You were sent an email with the event details' : 'Our team will be notified for your event and' +
		// 	' will get in touch with you promptly.'}`, 'Ok', { duration: 6000 });
		// });

		this.rest.getAdminEmails().subscribe(x => {
			let result: string[] = x.result;
			if (this.form.value.email) { result.push(this.form.value.email); }
			this.rest
				.sendEmails({
					to: result.join(', '),
					subject: this.subjectOnBuild(this.form.value.name),
					text: this.prepareEmailBody()
				})
				.subscribe(() => {
					this.snackBar.open(`${ this.isAdmin ? 'You were sent an email with the event details' : 'Our team will be notified for your event and' +
					' will get in touch with you promptly.'}`, 'Ok', { duration: 6000 });
				});
		});
	}

	private subjectOnBuild(name): String {
		const subject: String = '[BetterEvents] Your website for ' + name + ' has been created';
		return subject;
	}

	private subjectOnImport(name): String {
		const subject: String = '[BetterEvents] Content uploaded for ' + name;
		return subject;
	}

	private bodyOnImport(event) {
		let body: String;
		body = 'There is content prepared and uploaded for the ' + event.name +  '. Please check the content, schedule the import and inform the event manager.';
		body += '\n';
		body += '\nLink: https://betterevents.app/#/shell/conferences/' + (event.$key ? event.$key : 'not set');
		body += '\n';
		body += '\nCheers';
		body += '\nBetterEvents team';
		return body;
	}

	private prepareEmailBody(): String {
		let c = this.form.value;
		let body: String;
		body = 'The website for the ' + c.name +  ' has been created.';
		body += '\n';
		body += '\nWebsite: ' + this.domainUrl + '/' + (c.prefix ? c.prefix + c.startDate.year : 'not set');
		body += '\nEvent: ' + (c.name ? c.name : 'not set');
		// body += '\nLocation: ' + (c.location ? c.location : 'not set');
		body += '\nDates: ' + (c.startDate && c.endDate ? + c.startDate.day + ' - ' + c.endDate.day + '/' + c.startDate.month + '/' + c.startDate.year : 'not set');
		// body += '\nPosters: ' + (c.numberOfPosters ? c.numberOfPosters : 'not set');
		// body += '\nMeeting Manager: ' + (c.nameOfManager ? c.nameOfManager : 'not set');
		body += '\nCreated by: ' + this.userEmail;
		body += '\nEvent manager: ' + (c.email ? c.email : 'not set');
		body += '\n';
		body += '\nCheers';
		body += '\nBetterEvents team';
		// body += '\nOption: ' + (c.ePostersLiveService ? c.ePostersLiveService : 'not set');
		// body += '\nWebsite: ' + (c.webSite ? c.webSite : 'not set');
		// body += '\nPortal link: ' + (environment.production ? 'https://my.eposterslive.com/#/shell/conferences/' + c.$key : 'https://conference-portal-stage.firebaseio.com/#/shell/conferences/' + c.$key);

		return body;
	}

	public selectPlan(plan: string) {
		const url = 'http://sites.fastspring.com/appseed/product/better-events-' +
									plan + '?event=' + this.conference.$key;
		window.open(url, '_blank');
		// this.conference.plan = plan;
		// this.data.saveConference(this.conference).then(() => {
		//   console.log('Conference with selected plan saved successfully.')
		// })
	}

	public visitUpgrade() {
		this.store.index = 3;
	}
}
