import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { AboutComponent } from './about.component';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
	imports: [
		SharedModule,
		FormlyModule
	],
	declarations: [AboutComponent]
})
export class AboutModule {
}
