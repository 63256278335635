import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
	templateUrl: 'sign-in.component.html',
	styleUrls: ['sign-in.component.scss']
})

export class SignInComponent {
	loginForm: FormGroup;
	error: string;

	appName = '';
	appSubtitle = '';
	appSlogan = '';

	isLoading: boolean = false;
	privacyCboxValidationMessage: string = '';

	constructor(private auth: AuthService, private router: Router, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
			privacyCbox: [false, Validators.requiredTrue]
		});

		this.error = '';

		this.appName = environment.app.name;
		this.appSubtitle = environment.app.subtitle;
		this.appSlogan = environment.app.slogan;
	}

	reset() {
		let data = this.loginForm.value;
		this.auth.resetPassword(data.email).then(x => console.log(x));
	}

	signInWithEmail() {
		// if (this.privacyPolicyAgree()) {
			let data = this.loginForm.value;
			this.isLoading = true;
			this.auth.signInWithEmail(data.email, data.password).then(
				() => this.postSignIn(),
				error => {
					this.isLoading = false;
					this.error = error.message;
				}
			);
		// }
	}

	signInWithGoogle(): void {
		// if (this.privacyPolicyAgree()) {
			this.auth.signInWithGoogle()
				.then(() => this.postSignIn());
		// }
	}

	private privacyPolicyAgree(): boolean {
		if (!this.loginForm.get('privacyCbox').value) {
			this.privacyCboxValidationMessage = 'You must agree with our Privacy Policy to proceed.';
			return false;
		} else {
			return true;
		}
	}

	private postSignIn(): void {
		this.isLoading = false;
		this.router.navigate(['/shell']);
	}
}
