import { Injectable } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class AppStore {
	selectedConference: any;

	constructor() {
	}

	selectConference(conference) {
		this.selectedConference = conference;

		let json = JSON.stringify(conference);
		localStorage.setItem('conference', json);
	}

	loadConference() {
		let json = localStorage.getItem('conference');
		if (json) {
			this.selectedConference = JSON.parse(json);
		}
	}

	clearSelectedConference() {
		this.selectedConference = null;
		localStorage.removeItem('conference');
	}

	setUser(userId: string) {
		let json = JSON.stringify({ userId: userId });
		localStorage.setItem('user', json);
	}

	getUser() {
		let json = localStorage.getItem('user');
		if (json) {
			return JSON.parse(json);
		}
		return null;
	}

	flushUser() {
		localStorage.removeItem('user');
		this.clearSelectedConference();
	}
}