import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { RoleGuard } from '../auth/guards/role.guards';
import { SharedModule } from '../common/shared.module';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { ItemDetailsComponent } from './item-details.component';
import { ItemsListComponent } from './items-list.component';
import { NewItemDialog } from './new-item.dialog';

export const managerRoutes: Routes = [
	{
		path: 'manager/:itemsType',
		component: ItemsListComponent,
		pathMatch: 'full',
		data: { allowFor: ['admin'] },
		canActivate: [RoleGuard]
	},
	{
		path: 'manager/:itemsType/:id',
		component: ItemDetailsComponent,
		pathMatch: 'full',
		data: { allowFor: ['admin'] },
		canActivate: [RoleGuard]
	},
	{
		path: 'manager/:parentType/:parentId/:itemsType',
		component: ItemDetailsComponent,
		pathMatch: 'full',
		data: { allowFor: ['admin'] },
		canActivate: [RoleGuard]
	},
	{
		path: 'manager/:parentType/:parentId/:itemsType/:id',
		component: ItemDetailsComponent,
		pathMatch: 'full',
		data: { allowFor: ['admin'] },
		canActivate: [RoleGuard]
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
		CKEditorModule,
		AgmCoreModule,
		SharedModule,
		DynamicFormModule
	],
	exports: [],
	declarations: [
		ItemsListComponent,
		ItemDetailsComponent,
		NewItemDialog
	],
	entryComponents: [NewItemDialog]
})
export class ManagerModule {
}
