import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as FileSaver from 'file-saver';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AppStore } from '../common/app-store';
import { ConfirmDialog } from '../common/dialogs/confirm.dialog';
import { readAsText } from '../common/promise-file-reader';
import { DataService } from '../common/services/data.service';
import { parsePostersCsv } from './parser';
import { PosterValidator } from './poster-validator';
import { ValidationOption } from './validation-option';
import { ValidationOptionsDialog } from './validation-options.dialog';
import { Author } from './poster';

let arrayToCsv = require('../common/array-to-csv');

@Component({
	selector: 'kendo-grid-demo',
	styleUrls: ['./posters-grid.component.scss'],
	templateUrl: './posters-grid.component.html'
})
export class PostersGridComponent {
	isLoading = false;
	columns = [
		{ fieldName: 'code', title: 'Code' },
		{ fieldName: 'title', title: 'Title' },
		{ fieldName: 'topic', title: 'Topic' },
		{ fieldName: 'subtopic', title: 'Subtopic' },
		{ fieldName: 'session', title: 'Session' },
		{
			fieldName: 'corresponder',
			title: 'Corresponder',
			fields: [
				{ fieldName: 'firstName', title: 'First name' },
				{ fieldName: 'lastName', title: 'Last name' },
				{ fieldName: 'email', title: 'Email' }
			]
		}
	];

	@ViewChild('fileInput') fileInput: ElementRef;

	formGroup: FormGroup;
	view: GridDataResult;
	posters: any[] = [];

	private editedRowIndex: number;
	gridState$: BehaviorSubject<State> = new BehaviorSubject<State>({
		sort: [{ field: 'code', dir: 'asc' }],
		group: [],
		skip: 0,
		take: 10
	});

	private defaultModeHiddenColumns = ['corresponder.firstName', 'corresponder.lastName', 'corresponder.email'];
	private detailedModeHiddenColumns = [];
	hiddenColumns = [];

	private invalidOnly$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private validationOptions$$: BehaviorSubject<ValidationOption[]>;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private dataService: DataService,
		private appStore: AppStore,
		private ls: LocalStorageService,
		public validator: PosterValidator
	) {
		this.hiddenColumns = ls.retrieve('hiddenColumns') || [];
		this.validationOptions$$ = new BehaviorSubject(this.validator.options);
	}

	ngOnInit(): void {
		let posters$ = this.dataService.loadPosters(this.appStore.selectedConference.$key);
		this.gridState$.combineLatest(posters$, this.invalidOnly$$, this.validationOptions$$)
			.subscribe(result => {
				let invalidOnly: boolean = result[2];
				this.validator.options = result[3];
				let posters = result[1];
				let gridState = result[0];

				if (invalidOnly) {
					posters = posters.filter(x => !this.validator.isPosterValid(x, posters));
				}

				this.view = process(posters, gridState);
				this.posters = posters;
			});
	}

	isColumnHidden(column) {
		return this.hiddenColumns.indexOf(column) >= 0;
	}

	upload() {
		let fileInput: HTMLInputElement = this.fileInput.nativeElement;
		let fileCount: number = fileInput.files.length;
		if (fileCount > 0) {
			let file = fileInput.files.item(0);

			readAsText(file).then((csv: string) => {
				let result = parsePostersCsv(csv);
				if (result.error) {
					alert('There are errors in the file. Look at the console');
					console.log(result.error);
					return;
				}

				this.dataService.importPosters(result.data, this.appStore.selectedConference.$key);
			});
		}
	}

	onStateChange(state: State) {
		this.gridState$.next(state);
	}

	addHandler({ sender }) {
		this.closeEditor(sender);

		this.formGroup = this.fb.group({
			code: ['', Validators.required],
			title: '',
			topic: '',
			subtopic: '',
			session: '',
			corresponder: this.fb.group({
				firstName: '',
				lastName: '',
				email: ''
			})
		});

		sender.addRow(this.formGroup);
	}

	editHandler({ sender, rowIndex, dataItem }) {
		this.closeEditor(sender);

		this.formGroup = this.fb.group({
			id: dataItem.id,
			code: [dataItem.code, Validators.required],
			title: dataItem.title,
			topic: dataItem.topic,
			subtopic: dataItem.subtopic,
			session: dataItem.session,
			corresponder: this.fb.group({
				firstName: dataItem.corresponder.firstName,
				lastName: dataItem.corresponder.lastName,
				email: dataItem.corresponder.email
			})
		});

		this.editedRowIndex = rowIndex;

		sender.editRow(rowIndex, this.formGroup);
	}

	saveHandler({ sender, rowIndex, formGroup, dataItem }) {
		this.dataService.savePoster(formGroup.value, dataItem.$key, this.appStore.selectedConference.$key);
		sender.closeRow(rowIndex);
	}

	removeHandler({ dataItem }) {
		let data = {
			title: 'Delete poster',
			message: 'Do you want to delete the poster?',
			buttonConfirm: 'Delete',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.dataService.removePoster(dataItem);
			}
		});
	}

	cancelHandler({ sender, rowIndex }) {
		this.closeEditor(sender, rowIndex);
	}

	saveAuthorHandler(parentDataItem, { formGroup, dataItem }) {
		let poster = parentDataItem;
		let author = dataItem;
		this.dataService.savePosterAuthor(poster.$key, author.id, formGroup.value);
	}

	removeAuthorHandler(parentDataItem, { dataItem }) {
		let data = {
			title: 'Delete author',
			message: 'Do you want to delete the author?',
			buttonConfirm: 'Delete',
			buttonCancel: 'Cancel'
		};
		this.dialog.open(ConfirmDialog, { data: data })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.dataService.removePosterAuthor(parentDataItem.$key, dataItem.id);
			}
		});
	}

	enabledDefaultMode() {
		this.hiddenColumns = [...this.defaultModeHiddenColumns];
		this.saveHiddenColumns();
	}

	enableDetailedMode() {
		this.hiddenColumns = [...this.detailedModeHiddenColumns];
		this.saveHiddenColumns();
	}

	private closeEditor(grid, rowIndex = this.editedRowIndex) {
		grid.closeRow(rowIndex);
		this.editedRowIndex = undefined;
		this.formGroup = undefined;
	}

	hideColumn(field) {
		this.hiddenColumns.push(field);
		this.saveHiddenColumns();
		return false;
	}

	areCorrespondersHidden() {
		return this.hiddenColumns.filter(x => x.indexOf('corresponder.') === 0).length === 3;
	}

	saveHiddenColumns() {
		this.ls.store('hiddenColumns', this.hiddenColumns);
	}

	exportToCsv() {
		console.log('export');
		this.dataService.loadPosters(this.appStore.selectedConference.$key)
			.take(1)
			.toPromise()
			.then(posters => {
				let lines = [
					[
						'Code', 'Title', 'Topic', 'Subtopic', 'Session',
						'Corresponder First Name', 'Corresponder Last Name', 'Corresponder Email', 'Author First Name',
						'Author Last Name', 'Author Affiliation', 'Author Email'
					]
				];
				posters.forEach(poster => {
					Object.values(poster.authors).forEach((author: Author) => {
						let corresponder = poster.corresponder || { firstName: '', lastName: '', email: '' };

						let line = [
							poster.code, poster.title, poster.topic, poster.subtopic, poster.session,
							corresponder.firstName, corresponder.lastName, corresponder.email,
							author.firstName, author.lastName, author.affiliation, author.email
						];
						lines.push(line);
					});
				});

				let file = new File([arrayToCsv(lines, ',')], 'posters.csv', { type: 'text/plain;charset=utf-8' });
				FileSaver.saveAs(file);
			});
	}

	deleteAll() {
		let dialog = {
			title: 'Delete posters',
			message: 'You are going to delete all posters. Are you sure?'
		};
		this.dialog.open(ConfirmDialog, { data: dialog })
			.afterClosed().subscribe(status => {
			if (status === 'OK') {
				this.isLoading = true;
				this.dataService.loadPosters(this.appStore.selectedConference.$key)
					.take(1)
					.toPromise()
					.then(posters => {
						this.dataService.removeAllPosters(posters)
							.then(() => this.isLoading = false);
					});
			}
		});
	}

	allData = (): Promise<any> => {
		return this.dataService.loadPosters(this.appStore.selectedConference.$key)
			.take(1)
			.toPromise()
			.then(posters => {
				return {
					data: process(posters, { group: this.gridState$.value.group, sort: this.gridState$.value.sort }).data,
					group: this.gridState$.value.group
				};
			});
	};

	toggleShowingInvalid(value) {
		this.invalidOnly$$.next(value);
	}

	changeValidationOptions() {
		this.dialog.open(ValidationOptionsDialog, { data: { options: [...this.validator.options] } }).afterClosed()
			.subscribe(options => {
				if (options) {
					this.validationOptions$$.next(options);
				}
			});
	}
}
