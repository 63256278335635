import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'new-conference-dialog',
	templateUrl: 'new-conference.dialog.html',
	styleUrls: ['new-conference.dialog.scss']
})
export class NewConferenceDialog {
	form: FormGroup;

	constructor(
		private dialogRef: MatDialogRef<NewConferenceDialog>,
		private fb: FormBuilder
	) {
		this.form = this.fb.group({
			conferenceName: ['', Validators.required]
		});
	}

	onEnter() {
		if(this.form.valid) {
			this.create();
		}
	}
	
	create() {
		this.form.value.conferenceName = this.form.value.conferenceName.slice(0, 120);
		this.dialogRef.close(this.form.value);
	}

	cancel() {
		this.dialogRef.close();
	}
}
