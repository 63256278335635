import { Injectable } from '@angular/core';
import { RestService } from './rest.service';


@Injectable()
export class EmailService {
	constructor(
    private rest: RestService,
	) {
	}

  sendEmail(subject, body, recipient?) {
		this.rest.getAdminEmails().subscribe(x => {
			let result: string[] = x.result;
      if (recipient) { result.push(recipient); }
			this.rest
				.sendEmails({
					to: result.join(', '),
					subject: subject,
					text: body
				}).subscribe( res => console.log(res));
		});
	}
}
