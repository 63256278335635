import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

interface SqsParameters {
	MessageAttributes?: any;
	MessageBody: any;
	QueueUrl: string;
	MessageDeduplicationId: string;
	MessageGroupId: string;
}

export class SqsService {
	private sqs: AWS.SQS;
	private params: SqsParameters;
	readonly qUrl = { QueueUrl: 'https://sqs.eu-west-1.amazonaws.com/399314363191/as-web-stage.fifo' };

	constructor() {
		AWS.config.update({
			accessKeyId: 'AKIAJFPT677GAN4ZVLXA',
			secretAccessKey: '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',
			region: 'eu-west-1'
		});
		this.sqs = new AWS.SQS({ apiVersion: '2012-11-05' });

	}

	sendMsg(params: any) {
		let p = Object.assign({}, params, this.qUrl);
		return new Promise((resolve, reject) => {
			this.sqs.sendMessage(p, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	}
}
