import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppStore } from '../../common/app-store';


@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private appStore: AppStore) {}

	canActivate(): Promise<boolean> {
		return this.authService
			.auth()
			.map(authState => !!authState)
			.do(authenticated  => {
				if (!authenticated) {
					this.appStore.flushUser();
				} else {
					let user = this.appStore.getUser();
					if (!user || user.userId !== this.authService.id) {
						this.appStore.flushUser();
					}
					this.appStore.setUser(this.authService.id);
				}
			})
			.do(authenticated => {
				if (!authenticated) {
					this.router.navigate(['/sign-in']);
				}
			})
			.take(1)
			.toPromise();
	}
}
