import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'prefix',
	templateUrl: './prefix.component.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PrefixComponent), multi: true }
	]
})
export class PrefixComponent implements ControlValueAccessor, OnChanges {

	@Input('prefix') _prefix = '';
	@Input() readonly = false;

	get prefix() {
		return this._prefix;
	}

	set prefix(val) {
		this._prefix = val;
		this.propagateChange(val);
	}

	slag: string;

	private propagateChange: any = () => {};
	private onTouchedCallback: () => {};

	constructor() {
		this.slag = '';
	}

	ngOnChanges(inputs) {
		this.propagateChange(this.prefix);
	}

	writeValue(value) {
		this.slag = value;
	}

	onBlur() {
		this.onTouchedCallback();
	}

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouchedCallback = fn;
	}

	onSlagChange(slag) {
		this.slag = (slag || '').toLowerCase();
		this.prefix = this.slag;
	}

	keyPress(event: any) {
		const pattern = /[a-z]/i;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}
}