import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PosterValidator } from './poster-validator';

@Component({
	selector: 'details-grid',
	template: `
		<kendo-grid [data]="authors"
			(add)="addHandler($event)" (cancel)="cancelHandler($event)"
			(save)="saveHandler($event)" (remove)="removeHandler($event)"
			(edit)="editHandler($event)">

			<ng-template kendoGridToolbarTemplate>
				Authors <button class="ml-3" kendoGridAddCommand>Add</button>
			</ng-template>

			<kendo-grid-column [width]="50">
				<ng-template kendoGridCellTemplate let-dataItem>
					<mat-icon color="warn" *ngIf="!validator.isAuthorValid(dataItem, authors)" [matTooltip]="validator.authorErrorsSummary(dataItem, authors)"
						[matTooltipPosition]="'right'">info
					</mat-icon>
					<mat-icon color="primary" *ngIf="validator.isAuthorValid(dataItem, authors)">done</mat-icon>
				</ng-template>
			</kendo-grid-column>

			<ng-template ngFor [ngForOf]="columns" let-column>
				<kendo-grid-column field="{{column.fieldName}}" title="{{column.title}}">

					<ng-template kendoGridCellTemplate let-dataItem>
						<span class="whole-cell">
							{{ dataItem[column.fieldName] }}
							<mat-icon color="warn"
								*ngIf="validator.authorErrors(dataItem, authors)[column.fieldName]"
								[matTooltip]="validator.authorErrors(dataItem, posters)[column.fieldName]">info_outline</mat-icon>
						</span>
					</ng-template>

				</kendo-grid-column>
			</ng-template>

			<kendo-grid-command-column title="Commands" width="220">
				<ng-template kendoGridCellTemplate let-isNew="isNew">
					<button kendoGridEditCommand>Edit</button>
					<button kendoGridRemoveCommand class="k-primary">Remove</button>
					<button kendoGridSaveCommand>Save</button>
					<button kendoGridCancelCommand>Cancel</button>
				</ng-template>
			</kendo-grid-command-column>
		</kendo-grid>
	`
})
export class AuthorsGridComponent {
	@Input() item: any;

	@Output() save: EventEmitter<any> = new EventEmitter<any>();
	@Output() remove: EventEmitter<any> = new EventEmitter<any>();

	formGroup: FormGroup;

	columns = [
		{ fieldName: 'firstName', title: 'First name' },
		{ fieldName: 'lastName', title: 'Last name' },
		{ fieldName: 'email', title: 'Email' },
		{ fieldName: 'affiliation', title: 'Affiliation' }
	];

	private editedRowIndex: number;

	get authors(): any[] {
		if (!this.item.authors) {
			return [];
		}
		return Object.values(this.item.authors);
	}

	constructor(private fb: FormBuilder, public validator: PosterValidator) {

	}

	addHandler({ sender }) {
		this.closeEditor(sender);

		this.buildFormGroup();
		sender.addRow(this.formGroup);
	}

	editHandler({ sender, rowIndex, dataItem }) {
		this.closeEditor(sender);

		this.buildFormGroup(dataItem);
		this.editedRowIndex = rowIndex;
		sender.editRow(rowIndex, this.formGroup);
	}

	saveHandler(event) {
		this.save.emit(event);
		let { sender, rowIndex } = event;
		sender.closeRow(rowIndex);
	}

	removeHandler(event) {
		this.remove.emit(event);
	}

	cancelHandler({ sender, rowIndex }) {
		this.closeEditor(sender, rowIndex);
	}

	private closeEditor(grid, rowIndex = this.editedRowIndex) {
		grid.closeRow(rowIndex);
		this.editedRowIndex = undefined;
		this.formGroup = undefined;
	}

	private buildFormGroup(dataItem?: any) {
		let form: any = this.columns.reduce((acc, current) => {
			acc[current.fieldName] = dataItem && dataItem[current.fieldName] ? dataItem[current.fieldName] : '';
			return acc;
		}, {});
		if (dataItem) {
			form.id = dataItem.id;
		}
		this.formGroup = this.fb.group(form);
	}
}
