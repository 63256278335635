import { Component, Input } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';
import { AppStore } from '../../common/app-store';
import { environment } from '../../../environments/environment';


@Component({
	selector: 'top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
	@Input() sidenav: any;

	appSubtitle = '';

	get email() {
		return this.authService.getEmail();
	}

	constructor(
		private authService: AuthService,
		private router: Router,
		private appStore: AppStore
	) {
		this.appSubtitle = environment.app.subtitle;
	}

	signOut() {
		this.appStore.flushUser();
		this.authService.signOut().then(() => this.router.navigate(['/sign-in']));
	}
}