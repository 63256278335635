import { Injectable } from '@angular/core';
import { flatten, uniq } from 'lodash';
import { Author, Poster } from './poster';
import { ValidationOption } from './validation-option';

let validator = require('validator');

@Injectable()
export class PosterValidator {
	options: ValidationOption[] = [
		ValidationOption.authorAffiliationRequired,
		ValidationOption.authorEmailRequired,
		ValidationOption.authorNameRequired,
		ValidationOption.corresponderEmailRequired,
		ValidationOption.uniqueEmailAmongCorresponders
	];

	isPosterValid(poster: Poster, posters: Poster[]): boolean {
		return Object.values(this.errors(poster, posters))
			.every(firstLevel => !firstLevel
				|| (typeof firstLevel === 'object' && Object.values(firstLevel).every(secondLevel => !secondLevel))
			);
	}

	isAuthorValid(author: Author, authors: Author[]): boolean {
		return Object.values(this.authorErrors(author, authors)).every(firstLevel => !firstLevel);
	}

	errorsSummary(poster: Poster, posters: Poster[]): string {
		let errors = this.errors(poster, posters);
		let filter = Object.values(errors)
			.filter(firstLevel => !!firstLevel)
			.map(firstLevel => {
				return typeof firstLevel === 'object'
					? Object.values(firstLevel).filter(secondLevel => !!secondLevel)
					: firstLevel;
			});
		return flatten(filter).join('; ');
	}

	authorErrorsSummary(author: Author, authors: Author[]): string {
		let errors = this.authorErrors(author, authors);
		let filter = Object.values(errors).filter(firstLevel => !!firstLevel);
		return flatten(filter).join('; ');
	}

	authorErrors(author: Author, authors: Author[]): { [field: string]: any } {
		let errors: { [field: string]: any } = {
			email: this.validateAuthorEmail(author),
			affiliation: this.validateAuthorAffiliation(author),
			firstName: this.validateAuthorFirstName(author),
			lastName: this.validateAuthorLastName(author)
		};
		return errors;
	}

	errors(poster: Poster, posters: Poster[]): { [field: string]: any } {
		let errors: { [field: string]: any } = {
			corresponder: {
				email: this.validateCorrespondingAuthorEmail(poster, posters)
			},
			authors: this.validateAuthors(poster, posters)
		};
		return errors;
	}

	validateAuthorEmail(author): string {
		if (!this.isOptionEnabled(ValidationOption.authorEmailRequired)) {
			return null;
		}

		if (!author.email) {
			return 'Author\'s email missed';
		}

		if (!validator.isEmail(author.email)) {
			return `Author's email "${author.email}" is incorrect`;
		}

		return null;
	}

	private validateAuthors(poster: Poster, posters: Poster[]): string {
		if (!poster.authors) {
			return null;
		}

		let authors = Object.values(poster.authors);
		let errors = authors
			.map(author => {
				return Object.values(this.authorErrors(author, authors))
					.filter(message => !!message);
			});

		errors = uniq(flatten(errors));
		return errors.length ? errors.join(', ') : null;
	}

	private validateCorrespondingAuthorEmail(poster: Poster, posters: Poster[]): string {
		return this.validateCorrespondingAuthorEmailPresence(poster, posters)
			|| this.validateCorrespondingAuthorNameConsistency(poster, posters);
	}

	private validateCorrespondingAuthorEmailPresence(poster: Poster, posters: Poster[]): string {
		if (!this.isOptionEnabled(ValidationOption.corresponderEmailRequired)) {
			return null;
		}

		if (!poster.corresponder.email) {
			return 'Corresponding author email is empty';
		}

		if (!validator.isEmail(poster.corresponder.email)) {
			return 'Corresponding author email is incorrect';
		}

		return null;
	}

	private validateCorrespondingAuthorNameConsistency(poster: Poster, posters: Poster[]): string {
		if (!this.isOptionEnabled(ValidationOption.uniqueEmailAmongCorresponders) || !poster.corresponder.email) {
			return null;
		}

		let inconsistentPosters = posters.filter(
			x => x.corresponder.email === poster.corresponder.email
				&& (x.corresponder.firstName !== poster.corresponder.firstName || x.corresponder.lastName !== poster.corresponder.lastName)
		);
		return inconsistentPosters.length === 0
			? null
			: `Different corresponding authors with same email "${poster.corresponder.email}" in: ${inconsistentPosters.map(x => x.code).join(', ')}`;
	}

	private validateAuthorAffiliation(author: Author) {
		if (!this.isOptionEnabled(ValidationOption.authorAffiliationRequired)) {
			return null;
		}

		if (!author.affiliation) {
			return 'Author should have an affiliation';
		}

		return null;
	}

	private validateAuthorFirstName(author: Author) {
		if (!this.isOptionEnabled(ValidationOption.authorNameRequired)) {
			return null;
		}

		if (!author.firstName) {
			return 'Author should have a first name';
		}

		return null;
	}

	private validateAuthorLastName(author: Author) {
		if (!this.isOptionEnabled(ValidationOption.authorNameRequired)) {
			return null;
		}

		if (!author.lastName) {
			return 'Author should have a last name';
		}

		return null;
	}

	private isOptionEnabled(option: ValidationOption) {
		return this.options.some(x => x === option);
	}
}