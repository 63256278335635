import index from '@angular/cli/lib/cli';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyFormBuilder } from '@ngx-formly/core';
import * as clonedeep from 'lodash.clonedeep';

@Component({
	selector: 'formly-mater-details',
	template: `
		<div class="row">
			<div class="col-4">

				<div class="card form-group">
					<div class="card-header">
						<span *ngFor="let column of columns">{{column.templateOptions.label}}</span>
						<span *ngIf="autogeneratedTitleColumn">{{to['titleColumnLabel']}}</span>
						<button
							style="position: absolute; right: 15px;"
							type="button" mat-icon-button [disabled]="detailForm && detailForm.invalid" (click)="add(true)">
							<mat-icon>add</mat-icon>
						</button>
					</div>
					<div class="card-block">
						<table class="table">
							<tbody>
								<tr *ngFor="let item of model; let i = index;"
									[ngClass]="{ 'table-active': currentIndex == i }"
									(click)="selectItem(i)"
								>
									<td *ngFor="let column of columns" [ngStyle]="{ 'text-decoration': isRemoved(i) ? 'line-through' : 'none' }">{{item[column.key] || '[NEW]'}}</td>
									<td *ngIf="autogeneratedTitleColumn" [ngStyle]="{ 'text-decoration': isRemoved(i) ? 'line-through' : 'none' }">
										{{to.titleColumnValueGetter(item) || '[NEW]'}}
									</td>
									<td width="40px">
										<button type="button" color="warn" mat-icon-button (click)="remove(i)" *ngIf="!isRemoved(i)">
											<mat-icon>delete_forever</mat-icon>
										</button>
										<button type="button" mat-icon-button (click)="remove(i)" *ngIf="isRemoved(i)">
											<mat-icon>undo</mat-icon>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			</div>
			<div class="col-8" *ngIf="currentItem">

				<div class="card form-group">
					<div class="card-header">
						Citation
					</div>
					<div class="card-block" style="padding: 20px;">
						<form [formGroup]="detailForm" *ngIf="currentItem">
							<formly-form
								[form]="detailForm"
								[fields]="fields[currentIndex]"
								[model]="model[currentIndex]"
								[options]="options">
							</formly-form>
							<div class="stub" *ngIf="isRemoved(currentIndex)"></div>
						</form>
					</div>
				</div>

			</div>
		</div>
	`,
	styleUrls: ['./master-details.scss']
})
export class FormlyMasterDetails extends FieldType implements OnInit {
	formControl: FormArray;
	fields = [];

	removedIndexes: number[] = [];

	get columns(): FormlyFieldConfig[] {
		return this.field.fieldArray.fieldGroup.filter(x => x.templateOptions && x.templateOptions.showInGrid);
	}

	get detailForm() {
		let form = this.formControl.at(this.currentIndex);
		return form;
	}

	currentItem: any;
	currentIndex: number;

	get autogeneratedTitleColumn() {
		return !!this.to.titleColumnLabel;
	}

	constructor(private fb: FormBuilder, private builder: FormlyFormBuilder) {
		super();
	}

	getNewFields() {
		return clonedeep(this.field.fieldArray.fieldGroup);
	}

	ngOnInit() {
		if (this.model.length > 0) {
			this.selectItem(0);
		} else {
			this.selectItem(-1);
		}
		this.model.forEach(() => this.add(false));

		if (this.formState.onFlush) {
			this.formState.onFlush.subscribe(() => {
				this.removedIndexes.forEach(i => {
					this.formControl.removeAt(i);
					this.model.splice(i, 1);
					this.fields.splice(i, 1);
				});
				this.model.length > 0 ? this.selectItem(0) : this.selectItem(-1);
				this.removedIndexes = [];
			});
		}
	}

	selectItem(index: number) {
		if (this.detailForm && this.detailForm.invalid) {
			alert('The form is invalid');
			return;
		}

		if (this.currentIndex === index) {
			return;
		}
		this.currentIndex = index;

		if (index >= 0) {
			this.currentItem = this.model[index];
		} else {
			this.currentItem = null;
		}
	}

	add(createNew: boolean) {
		const form = new FormGroup({}),
			i = this.fields.length;

		if (!this.model[i]) {
			let newModel = {};
			if (this.to.titleColumnValueSetter) {
				this.to.titleColumnValueSetter(newModel, i + 1);
			}
			this.model.push(newModel);
		}

		this.fields.push(this.getNewFields());
		this.builder.buildForm(form, this.fields[i], this.model[i], this.options);
		this.formControl.push(form);

		if (createNew) {
			this.formControl.markAsDirty();
			this.selectItem(this.model.length - 1);
		}
	}

	remove(ind) {
		let position = this.removedIndexes.indexOf(ind);
		if (position >= 0) {
			this.removedIndexes.splice(position, 1);
		} else {
			this.removedIndexes.push(ind);
		}

		this.formControl.markAsDirty();
	}

	isRemoved(ind) {
		return this.removedIndexes.indexOf(ind) >= 0;
	}
}
