import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { ConfirmDialog } from '../common/dialogs/confirm.dialog';
import { SharedModule } from '../common/shared.module';
import { ConferenceComponent } from './conference.component';
import { ConferencesComponent } from './conferences.component';
import { NewConferenceDialog } from './new-conference.dialog';
import { AsCountdownModule } from '../common/components/countdown/countdown.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



@Injectable()
export class CanDeactivateConferenceGuard implements CanDeactivate<ConferenceComponent> {
	constructor(private dialog: MatDialog) {
	}

	canDeactivate(
		component: ConferenceComponent,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		if (component.canDeactivate()) {
			return true;
		}

		let data = {
			title: 'Leave page?',
			message: 'You haven\'t saved your progress. Do you want to leave without saving?',
			buttonConfirm: 'Leave this page',
			buttonCancel: 'Stay on this page'
		};
		return this.dialog.open(ConfirmDialog, { data: data })
			.afterClosed()
			.map(x => x === 'OK');
	}
}

export const conferenceRoutes: Routes = [
	{ path: 'conferences', component: ConferencesComponent, pathMatch: 'full' },
	{ path: 'conferences/:id', component: ConferenceComponent, canDeactivate: [CanDeactivateConferenceGuard], pathMatch: 'full' }
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
		CKEditorModule,
		SharedModule,
		AsCountdownModule,
		MatTooltipModule,
		MatSlideToggleModule
	],
	declarations: [ConferenceComponent, ConferencesComponent, NewConferenceDialog],
	entryComponents: [NewConferenceDialog],
	providers: [CanDeactivateConferenceGuard]
})
export class ConferencesModule {
}
