const wordsToSkip = ['a', 'of', 'an', 'the'];

export const buildPrefix = (name: string) => {
	if (!name) {
		return '';
	}

	return name
		.toLowerCase()
		.split(' ', 4)
		.map(x => x.trim())
		.filter(x => x.length > 0)
		.filter(x => wordsToSkip.indexOf(x) < 0)
		.map(x => x.substr(0, 1))
		.filter((char: string) => {
			const pattern = /[a-z]/i;
			return pattern.test(char[0]);
		})
		.join('');
};
