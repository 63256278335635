import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { AppStore } from '../common/app-store';

@Component({
	selector: 'shell',
	templateUrl: 'shell.component.html',
	styleUrls: ['./shell.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ShellComponent {
	mode = 'side';
	opened = false;

	get isVisible(): boolean {
		return this.authService.isAdmin();
	}

	constructor(public appStore: AppStore, private authService: AuthService) {
		this.appStore.loadConference();
	}

	ngOnInit() {
		this.fitLayoutToWidth();
	}

	@HostListener('window:resize' , ['$event'])
	onResize() {
		this.fitLayoutToWidth(event);
	}

	private fitLayoutToWidth(event?) {
		let width = event ? event.target.innerWidth : window.innerWidth;
		if (width < 960) {
			this.mode = 'over';
			this.opened = false;
		} else {
			this.mode = 'side';
			this.opened = true;
		}
	}

	closeSidenav(sidenav) {
		if (this.mode === 'over') {
			sidenav.close();
		}
	}
}
