export const environment = {
	// domainUrl: 'https://conferences.appseed.io',
	domainUrl: 'https://betterevents.live',
	directDomainUrl: 'https://direct.betterevents.live',
	production: false,
	app: {
		name: 'Better Events',
		subtitle: 'Better Events',
		slogan: 'Beautiful event websites in less than 10 minutes'
	},
	fire: {
		auth: {
			apiKey: 'AIzaSyBPHyAZSg5p2EF12Q-5zwFN8yW7Wn8iqeA',
			// authDomain: 'conference-portal-stage.firebaseapp.com',
			authDomain: 'betterevents.app',
			databaseURL: 'https://conference-portal-stage.firebaseio.com',
			projectId: 'conference-portal-stage',
			storageBucket: 'conference-portal-stage.appspot.com',
			messagingSenderId: '877195385133'
		}
	},
	s3: {
		accessKeyId: 'AKIAJFPT677GAN4ZVLXA',
		secretAccessKey: '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',
		bucket: 'dev.appseed.io/mobile-apps/businessdirectory-frb-admin'
	},
	cloudFunctions: {
		baseUrl: 'https://us-central1-conference-portal-stage.cloudfunctions.net/'
		// baseUrl: 'https://us-central1-scigen-portal-stage.cloudfunctions.net/'
	},
	backend: {
		baseUrl: 'https://4a4kxkgt50.execute-api.eu-west-1.amazonaws.com/prod/',
		key: 'hJUiXthSyz3QsKPaFkxu3QsiqHivA4ExifOctn90'
	}
};
