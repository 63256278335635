import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ValidationService } from '../../formly/validation.service';


@Component({
	selector: 'form-validation-summary',
	templateUrl: './form-validation-summary.html',
	styleUrls: ['./form-validation-summary.scss']
})
export class FormValidationSummary {
	@Input() form: FormGroup;
	@Input() fields: { [name: string]: FormlyFieldConfig[] };
	@Input() prefix: any;

	tabNames = ['details'];
	tabLabels = {
		'details': '1. Event Details',
	};

	get formErrors() {
		return Object.keys(this.fields)
			.map(tab => {
				let recusrionErrors = [] ;
				let errors = this.fields[tab]
				.map(
					function findKey(field): any {
						return Object.keys(field).forEach(property => {
					// .map(field => {
					// 	if (!field.key) {
					// 		return;
					// 	}
					//
					// 	let formControl = this.form.controls[field.key];
					// 	if (!formControl) {
					// 		return;
					// 	}
							if (field[property] && property === 'key') {
								let formControls = field.formControl;  // this.form.controls[field.key];
								if (formControls) {

									for (let error in formControls.errors) {
										if (formControls.errors.hasOwnProperty(error)) {
											let message = ValidationService.getValidatorErrorMessage(error);

											if (field.validation && field.validation.messages && field.validation.messages[error]) {
												message = field.validation.messages[error];
											}

											['validators', 'asyncValidators'].map(validators => {
												if (field[validators] && field[validators][error] && field[validators][error].message) {
													message = field.validators[error].message;
												}
											});

											if (typeof message === 'function') {
												recusrionErrors.push(message(formControls.errors[error], field));
											}

											if (message) {
												recusrionErrors.push(`<strong>${field.templateOptions.label}</strong>: ${message.replace('. ', '.<br>')}`);
											}

										}
									}
								}
							} else if (property !== 'formControl' && field[property] && typeof field[property] === 'object') {
								return findKey(field[property]);
							}
						});
					}
				)
				.filter(error => !!error);

				errors = recusrionErrors;

				let recusrionWarnings = [] ;
				let warnings = this.fields[tab]
					.map(
						function findKey(field): any {
							return Object.keys(field).forEach(property => {
						// .map(field => {
						// if (!field.key || !field.expressionProperties || !field.expressionProperties['templateOptions.warn']) {
						// 	return;
						// }

								if (field[property] && property === 'key') {
									if (field.templateOptions && field.templateOptions.warn) {
										recusrionWarnings.push(`<strong>${field.templateOptions.label}</strong>: ${field.templateOptions.warnMessage.replace('. ', '.<br>')}`);
									}
								} else if (property !== 'formControl' && field[property] && typeof field[property] === 'object') {
									return findKey(field[property]);
								}
							});
						}
					)
					.filter(error => !!error);

					warnings = recusrionWarnings;

				return {
					tabName: tab,
					errors: errors,
					warnings: warnings
				};
			});
	}
}
