import { NgModule } from '@angular/core';

import { AsCountdownComponent } from './countdown.component';

@NgModule({
    declarations: [
        AsCountdownComponent
    ],
    exports: [
        AsCountdownComponent
    ],
})
export class AsCountdownModule {
}
